import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Stack, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import { UserContext } from '../App';
import { createPaymentIntent, getPageSetting, getUserDomain, getWishlistList, searchDomain, updateWishlist } from '../helper/helper';
import Footer from '../pages/Footer';
import CheckoutForm from './CheckoutForm';
import DomainListSlider from './DomainListSlider';
import Faq from './Home/Faq';
import Reviews from './Home/Reviews';
import { FillHeart, Guarantee, Heart, MasterCard, Ownership, Thumbs_up, Visa } from './Icon';
import { Spinner } from './Loader';
import MetaTags from './MetaTags';
import Search from './Search';

// const stripePromise = loadStripe('pk_test_51OqrHcSGwlIjpkUj5EQAkOJR9oGAZvDQMrWBk1YRAJc2NBBzac6nRCskPCBL9oiRKYBS43QKbeJyyo61Bj9huFVn00IYAbZya7');

export default function DomainDetailsPage() {
    const { user, setUser } = useContext(UserContext);
    const params = useParams();
    const [domainData, setdomainData] = useState();
    const [pageSettings, setpageSettings] = useState();
    const [bannerData, setbannerData] = useState();
    const [faqData, setfaqData] = useState();
    const [loading, setloading] = useState(true);
    const navigate = useNavigate();
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [buynowLoading, setbuynowLoading] = useState(false);
    const [wishlist, setwishlist] = useState([]);
    const [wishlistShow, setwishlistShow] = useState(true);
    const [wishlistUse, setwishlistUse] = useState([]);
    const [wishlistSlider, setwishlistSlider] = useState([]);

    // const [model, setmodel] = useState(false);
    // const [saveLoading, setsaveLoading] = useState(false);
    // const [shipping, setshipping] = useState({
    //     line1: '',
    //     postal_code: '',
    //     city: '',
    //     state: '',
    //     country: '',
    // })

    // console.log('use', wishlistUse);

    useEffect(() => {
        async function fetchFirstData() {
            if (params && params.name) {
                let domainPromise = getUserDomain(params.name);
                domainPromise.then((res) => {
                    if (res && res.data) {
                        if (res.data.category && res.data.category.length > 0) {
                            let wishlistUsePromise = getWishlistList({ wishlist: res.data.category });
                            wishlistUsePromise.then((resUse) => {
                                setwishlistUse(resUse.data || [])
                            }).catch((error) => {
                                setwishlistUse([])
                            })

                            const joinWishlist = res.data.category.join(',');
                            let domainPromise = searchDomain({ category: joinWishlist });
                            domainPromise.then((resDomain) => {
                                if (resDomain?.data && resDomain?.data?.domain_res && resDomain.data.domain_res.length > 0) {
                                    const updatedData = resDomain.data.domain_res.filter((item) => item.name !== params.name);
                                    setwishlistSlider(updatedData || [])
                                } else {
                                    setwishlistSlider([])
                                }
                            }).catch((error) => {
                                setwishlistSlider([])
                            })
                        }
                        setdomainData(res.data);
                    } else {
                        setdomainData(null);
                    }
                }).catch(error => {
                    setdomainData(null);
                    // console.log('Login error:', error);
                });
            }
        }
        fetchFirstData();
    }, [params])

    useEffect(() => {
        async function fetchData() {
            const response = await getPageSetting('domain');
            if (!response.error) {
                setpageSettings(response.data);
                setloading(false);
            }

            setStripePromise(loadStripe(process.env.REACT_APP_LOAD_STRIPE));
        }
        fetchData();
    }, []);


    useEffect(() => {
        if (user && user !== "" && user !== undefined) {
            setwishlist(user.wishlist);
            if (user.user_type === "2") {
                setwishlistShow(true);
            } else {
                setwishlistShow(false);
            }
        } else {
            setwishlist([]);
            setwishlistShow(true);
        }
    }, [user]);


    useEffect(() => {
        if (pageSettings && pageSettings !== "") {
            setbannerData(pageSettings.data.banner);
            setfaqData(pageSettings.data.faq);
        }
    }, [pageSettings])

    const handleBuyNow = () => {
        if (user && user !== "" && user !== null) {
            if (user?.shipping && user.shipping !== "" && user.shipping !== null) {
                // console.log('shipping', user.shipping);
                if (domainData && domainData !== "" && domainData !== null) {
                    setbuynowLoading(true);
                    const paymentPromise = createPaymentIntent({ amount: domainData?.price || 9999, shipping: user.shipping, name: user.first_name, metadata: { domain_id: domainData._id, buyer_id: user._id, seller_id: domainData.user_id, domain_name: domainData.name } });
                    paymentPromise.then((res, req) => {
                        const { data } = res;
                        // console.log('darta', data);
                        if (data && data !== "") {
                            setClientSecret(data.clientSecret)
                        }
                        setbuynowLoading(false);
                    }).catch(error => {
                        setbuynowLoading(false);
                        // console.log('Login error:', error);
                    });
                }
            } else {
                const myPromise = new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 3000);
                });

                toast.promise(myPromise,
                    {
                        loading: 'First you have to add Address',
                        success: 'Redirecting...',
                        error: 'Something went wrong!',
                    }, { duration: 1500, }
                );

                myPromise.then(() => {
                    setTimeout(() => {
                        navigate('/dashboard/account');
                    }, 1500);
                });
            }

        } else {
            navigate('/login');
        }
    }

    const handleClickWishlist = (id) => {
        if (user && user !== "") {
            let newWishlist = [...wishlist];
            if (wishlist.includes(id)) {
                newWishlist = wishlist.filter((tldId) => tldId !== id);
            } else {
                newWishlist.push(id);
            }
            let wishlistPromise = updateWishlist({ wishlist: newWishlist });
            wishlistPromise.then(() => {
                setwishlist(newWishlist);
                setUser(preData => ({ ...preData, wishlist: newWishlist }))
            }).catch(error => {
                // console.log('Login error:', error);
            });

        } else {
            navigate('/login');
        }
    }

    const isItemInWishlist = (itemId, wishlistArray) => {
        return wishlistArray.includes(itemId);
    };

    const renderHTML = (htmlString) => {
        return { __html: htmlString };
    };
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setsaveLoading(true);
    //     let userPromise = updateUser({ first_name: user.first_name, last_name: user.last_name, email: user.email, shipping: shipping });
    //     userPromise.then((res) => {
    //         console.log('res', res);
    //         setUser(preData => ({ ...preData, ['shipping']: shipping }));
    //         setsaveLoading(false);
    //         setmodel(false);
    //         handleBuyNow();
    //     }).catch(error => {
    //         // console.log('Login error:', error);
    //         setsaveLoading(false);
    //     });
    // };


    return (
        <>
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            {loading ?
                <div className="flex items-center justify-center h-screen w-full">
                    <Stack direction="row" alignItems="center" gap={2} >
                        <Spinner />
                    </Stack>
                </div>
                :
                <>
                    {domainData && domainData !== "" ?
                        <>
                            <MetaTags title={`Xidomains.com is for sale - ${domainData.name}`} description={`${domainData.name} details for more information.`} />
                            <div className=''>
                                <div className='max-w-[1440px] m-auto p-5'>
                                    <div className='flex flex-wrap justify-between items-start'>
                                        <div className='w-full md:w-[48%] bg-white text-black rounded-xl p-5 md:p-8 shadow-xl grid gap-4'>
                                            <div>
                                                <div className='flex justify-between items-center'>
                                                    <h2 className='capitalize font-bold text-[25px] md:text-[40px]'>{domainData.name}</h2>
                                                    {wishlistShow &&
                                                        <div className='text-[#009A0A]'>
                                                            {isItemInWishlist(domainData._id, wishlist) ? (<FillHeart />) : (<Heart />)}
                                                        </div>
                                                    }
                                                </div>
                                                <p className=' mt-2'>This domain name is for sale.</p>
                                            </div>
                                            {wishlistShow &&
                                                <button onClick={() => { handleClickWishlist(domainData._id); }} type="button" className="text-[#425CD9] bg-[#F0F2FF] rounded-[32px] max-w-[200px] px-5 py-5 focus:outline-none text-[16px]">
                                                    {isItemInWishlist(domainData._id, wishlist) ? (<FillHeart />) : (<Heart />)}
                                                    <span className='ml-2'>Add to Shortlist </span>
                                                </button>
                                            }
                                            <h3 className='font-medium text-[20px] md:text-[30px]'>$ {domainData?.price} USD</h3>

                                            {clientSecret && stripePromise ? (
                                                <Elements stripe={stripePromise} options={{ clientSecret }}>
                                                    <CheckoutForm />
                                                </Elements>
                                            ) :
                                                <>
                                                    {domainData.status === "1" ?
                                                        <LoadingButton loading={buynowLoading} variant="contained" sx={{
                                                            color: "#fff",
                                                            // background: "linear-gradient(90deg, rgba(255,122,142,1) 10%, rgba(155,186,249,1) 86%)" 
                                                            backgroundColor: "#425CD9",
                                                            padding: "10px",
                                                            borderRadius: "5px",
                                                            boxShadow: "unset"
                                                        }} onClick={() => { handleBuyNow() }}>Proceed to pay</LoadingButton>
                                                        :
                                                        <Button variant="contained" color="primary" sx={{
                                                            color: "#fff",
                                                            // background: "linear-gradient(90deg, rgba(255,122,142,1) 10%, rgba(155,186,249,1) 86%)" 
                                                            backgroundColor: "#425CD9"

                                                        }}>Sold Out</Button>
                                                    }
                                                </>
                                            }
                                            <div className='flex items-center'>
                                                <Visa className='!text-[2.5rem]' />
                                                <div className="ml-5">
                                                    <MasterCard className='!text-[2.5rem]' />
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <div className="flex flex-col">
                                                    {/* <TPricing /> */}
                                                    <Guarantee sx={{ color: "#425CD9", width: "40px", height: "40px" }} />
                                                    <span className='mt-3'>30-day money back guarantee</span>
                                                </div>
                                                <hr className='w-[1px] h-full bg-[#C0C1C5]' />
                                                <div className="flex flex-col ml-2">
                                                    {/* <STranfer /> */}
                                                    <Ownership sx={{ color: "#425CD9", width: "40px", height: "40px" }} />
                                                    <span className='mt-3'>Take immediate ownership</span>
                                                </div>
                                                <hr className='w-[1px] h-full bg-[#C0C1C5]' />
                                                <div className="flex flex-col ml-2">
                                                    {/* <GDelivery /> */}
                                                    <Thumbs_up sx={{ color: "#425CD9", width: "40px", height: "40px" }} />
                                                    <span className='mt-3'>Safe and secure shopping</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-[50%]">
                                            <h2 className='capitalize font-bold mt-5 md:mt-0 text-[22px] md:text-[32px]'>About {domainData.name}</h2>
                                            <div className='mt-2 mb-4'>
                                                {domainData?.description && (
                                                    <div className='text-justify' dangerouslySetInnerHTML={renderHTML(domainData.description)} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {wishlistUse && wishlistUse.length > 0 &&
                                        <div className='mt-10 mb-5'>
                                            <h2 className='capitalize font-bold text-[25px] md:text-[40px] mb-5'>Possible Uses</h2>
                                            <div className='flex items-center gap-5 flex-wrap'>
                                                {wishlistUse.map((item, index) => {
                                                    return (
                                                        <span key={index} className="text-[#425CD9] min-w-28 text-center bg-[#F0F2FF] rounded-[32px] max-w-[200px] px-5 py-5 focus:outline-none text-[16px]">
                                                            {item.name}
                                                        </span>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    }
                                    {wishlistSlider && wishlistSlider.length > 0 &&
                                        <div className='mt-10 mb-5'>
                                            <h2 className='capitalize font-bold text-[25px] md:text-[40px] mb-5'>You May Also Like</h2>
                                            <div className='flex items-center gap-5'>
                                                <DomainListSlider data={wishlistSlider} slide={4} blockId='test' />
                                            </div>
                                        </div>
                                    }
                                </div>


                                {/* <Box sx={{
                                // background: "linear-gradient(90deg, rgba(255,122,142,1) 10%, rgba(155,186,249,1) 86%)",
                                backgroundColor: "#fff",
                                color: "#343434",
                                padding: { xs: 3, sm: 8 },
                                maxWidth: 800,
                                margin: "30px auto",
                                borderRadius: "10px",
                                textAlign: "left",
                                boxShadow: "0px 1px 8px hsla(210, 100%, 25%, 0.5)",
                            }}>
                                <div className='flex justify-between items-center'>
                                    <h2 className='capitalize font-bold text-[25px] md:text-[40px]'>{domainData.name}</h2>
                                    <div className='text-[#009A0A]'>
                                        <Heart />
                                    </div>
                                </div>
                                <p className=' mt-2 mb-4'>This domain name is for sale.</p>
                                {!clientSecret && <Typography variant='body2' sx={{ mt: 2 }}>{domainData?.description}</Typography>}
                                <Typography variant='h5' sx={{ mt: 2, color: "#000" }}>$ {domainData?.price}</Typography>

                                {clientSecret && stripePromise ? (
                                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                                        <CheckoutForm />
                                    </Elements>
                                ) :
                                    <>
                                        {domainData.status === "1" ?
                                            <LoadingButton loading={buynowLoading} variant="contained" sx={{ mt: 2, color: "#fff", background: "linear-gradient(90deg, rgba(255,122,142,1) 10%, rgba(155,186,249,1) 86%)" }} onClick={() => { handleBuyNow() }}>Buy Now</LoadingButton>
                                            :
                                            <Button variant="contained" color="primary" sx={{ mt: 2, color: "#fff", background: "linear-gradient(90deg, rgba(255,122,142,1) 10%, rgba(155,186,249,1) 86%)" }}>Sold Out</Button>
                                        }
                                    </>
                                }

                                <Modal
                                    open={model}
                                    sx={{ backgroundColor: "#00000099" }}
                                    // onClose={handleClose}
                                    aria-labelledby="parent-modal-title"
                                    aria-describedby="parent-modal-description"
                                >
                                    <Box component="form" onSubmit={handleSubmit} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "calc(100% - 20px)", borderRadius: "10px", maxWidth: 535, bgcolor: 'background.paper', margin: "20px auto", boxShadow: 24, pt: 2, px: 4, pb: 3, }}>
                                        <Typography variant='h5' align='center'>You have to add address</Typography>
                                        <Stack spacing={2} sx={{ mb: 2, mt: 2 }}>
                                            <TextField value={shipping.line1} onChange={(e) => setshipping(preData => ({ ...preData, ['line1']: e.target.value }))} size="small" required id="line1" label="Address" variant="outlined" fullWidth />
                                            <TextField value={shipping.country} onChange={(e) => setshipping(preData => ({ ...preData, ['country']: e.target.value }))} size="small" required id="country" label="Country" variant="outlined" fullWidth />
                                            <TextField value={shipping.state} onChange={(e) => setshipping(preData => ({ ...preData, ['state']: e.target.value }))} size="small" required id="state" label="State" variant="outlined" fullWidth />
                                            <TextField value={shipping.city} onChange={(e) => setshipping(preData => ({ ...preData, ['city']: e.target.value }))} size="small" required id="city" label="City" variant="outlined" />
                                        </Stack>
                                        <Grid item xs={12} sm={6}>
                                            <LoadingButton loading={saveLoading} type="submit" variant="contained" fullWidth>
                                                Submit
                                            </LoadingButton>
                                        </Grid>
                                    </Box>
                                </Modal>

                            </Box> */}
                                <Reviews />
                                <Faq data={faqData} />
                                <Search data={bannerData} />
                                <Footer />
                            </div>
                        </>
                        :
                        <div className='w-full flex flex-col justify-center items-center h-screen'>
                            <Typography variant='h3'>There Is No Data Available!</Typography>

                            <Button variant="contained" sx={{ mt: 3 }} onClick={() => { navigate('/search') }}>Back to List</Button>
                        </div>
                    }
                </>
            }
        </>
    );
}