import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Box, Button, Card, Container, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import MetaTags from '../componets/MetaTags';
import { login, loginUserWithGoogle, loginWithGoogleUpdate, logout } from '../helper/helper';
import Footer from './Footer';

function Login() {
    const navigate = useNavigate();
    const location = useLocation(); // Use location to access state
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [userModel, setuserModel] = useState(false);
    const [userData, setuserData] = useState();


    const inviteToken = location.state?.inviteToken;
    const inviteEmail = location.state?.inviteEmail;

    const handleItemClick = (index) => {
        setSelectedItemIndex(index);
    };

    const items = [
        {
            icon: <BadgeIcon />,
            title: 'Join as a market place seller',
            description: 'I plan to submit name ideas, Logo designs or sell names in Domain Marketplaces',
        },
        {
            icon: <AccountCircleIcon />,
            title: 'Join as a buyer',
            description: 'I am looking for a name, logo or tagline for my business, brand or product.',
        },
    ];

    useEffect(() => {
        logout();
    }, []);

    const formik = useFormik({
        initialValues: {
            email: inviteEmail || '',
            password: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values) => {
            let loginPromise = login(values);
            loginPromise
                .then((res) => {
                    let { token } = res.data;
                    localStorage.setItem('token', token);
                    toast.success('Login Successfully..!');

                    // Redirect based on invitation
                    if (inviteToken) {
                        navigate(`/access-dashboard/${inviteToken}`);
                    } else {
                        navigate('/dashboard');
                    }
                })
                .catch((error) => {
                    toast.error(error);
                });
        },
    });

    async function loginwithgoogle(tokenResponse) {
        try {
            let registerWithGooglePromise = loginUserWithGoogle(tokenResponse);

            registerWithGooglePromise
                .then((res) => {
                    let { token, user } = res.data;
                    if (user.user_type !== "3") {
                        toast.success("Login Successfully..!");
                        localStorage.setItem('token', token);

                        // Redirect based on invitation
                        if (inviteToken) {
                            navigate(`/access-dashboard/${inviteToken}`);
                        } else {
                            navigate('/dashboard');
                        }
                        setuserModel(false);
                    } else {
                        setuserData(user);
                        setuserModel(true);
                    }
                })
                .catch((error) => {
                    toast.error(error);
                });
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = () => {
        try {
            if (userData && userData !== "") {
                let registerWithGooglePromise = loginWithGoogleUpdate({
                    email: userData.email,
                    status: (selectedItemIndex + 1).toString() || '',
                });

                registerWithGooglePromise
                    .then((res) => {
                        let { token } = res.data;
                        toast.success("Login Successfully..!");
                        localStorage.setItem('token', token);

                        // Redirect based on invitation
                        if (inviteToken) {
                            navigate(`/access-dashboard/${inviteToken}`);
                        } else {
                            navigate('/');
                        }
                    })
                    .catch((error) => {
                        toast.error(error);
                    });
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <MetaTags title="Login" description="Login your details." />
            <Toaster position="top-center" reverseOrder={false}></Toaster>
            <Container
                id="register_form"
                sx={{
                    pt: { xs: 4, sm: 5 },
                    pb: { xs: 4, sm: 5 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: { xs: "100%", sm: 600 },
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Card
                    sx={(theme) => ({
                        p: 3,
                        height: 'fit-content',
                        width: '100%',
                        background: 'none',
                        '&:hover': {
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                    : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                        },
                    })}
                >
                    <Box sx={{ p: { xs: 2, sm: 5 }, borderRadius: 5, width: "100%" }}>
                        <Typography variant="h2" sx={{ fontSize: { xs: "30px", sm: "40px" }, marginBottom: "10px" }} align="center">
                            Login Account
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            We always keep your name and email private
                        </Typography>
                        <br />
                        <form className="py-1" onSubmit={formik.handleSubmit}>
                            <Stack spacing={2}>
                                <TextField
                                    size="small"
                                    {...formik.getFieldProps('email')}
                                    required
                                    id="email"
                                    label="Email Address"
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                />
                                <TextField
                                    size="small"
                                    {...formik.getFieldProps('password')}
                                    required
                                    id="password"
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                />
                            </Stack>
                            <div className="w-full flex justify-end mt-4 mb-2 cursor-pointer">
                                <Button onClick={() => navigate('/recovery')} variant="text" sx={{ p: 0 }}>
                                    Forgot Password?
                                </Button>
                            </div>
                            <Stack spacing={2} alignItems={'center'}>
                                <Button variant="contained" type="submit" color="primary" fullWidth>
                                    Continue
                                </Button>
                                <GoogleLogin
                                    type="standard"
                                    className="bg-transparent"
                                    text="Sign up with Google"
                                    size="large"
                                    theme="filled_black"
                                    fullWidth
                                    onSuccess={(credentialResponse) => {
                                        loginwithgoogle(credentialResponse);
                                    }}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                />
                            </Stack>
                        </form>
                    </Box>
                </Card>
            </Container>
            <div>
                <div
                    id="static-modal"
                    data-modal-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                    className={`${userModel ? "flex" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100%] max-h-full bg-slate-800/[.4]`}
                >
                    <div className="relative p-4 w-full max-w-2xl max-h-full">
                        <div className="relative bg-white rounded-lg shadow shadow-black">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                                <h3 className="text-xl font-semibold text-gray-900">Who you want to be?</h3>
                            </div>
                            <div className="p-4 md:p-5 space-y-4">
                                <Grid container spacing={3}>
                                    {items.map(({ icon, title, description }, index) => (
                                        <Grid key={index} item xs={12} sm={6}>
                                            <Card
                                                component={Button}
                                                onClick={() => handleItemClick(index)}
                                                sx={(theme) => ({
                                                    p: 3,
                                                    height: 'fit-content',
                                                    width: '100%',
                                                    background: 'none',
                                                    textTransform: 'none',
                                                    ...(selectedItemIndex === index && {
                                                        backgroundColor: 'action.selected',
                                                        borderColor:
                                                            theme.palette.mode === 'light'
                                                                ? 'primary.light'
                                                                : 'primary.dark',
                                                    }),
                                                    '&:hover': {
                                                        background:
                                                            theme.palette.mode === 'light'
                                                                ? 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)'
                                                                : 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                                                        borderColor:
                                                            theme.palette.mode === 'light'
                                                                ? 'primary.light'
                                                                : 'primary.dark',
                                                        boxShadow:
                                                            theme.palette.mode === 'light'
                                                                ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                                                : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                                                    },
                                                })}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        textAlign: 'left',
                                                        flexDirection: { xs: 'column', md: 'row' },
                                                        alignItems: { md: 'center' },
                                                        gap: 2.5,
                                                    }}
                                                >
                                                    <Box
                                                        sx={(theme) => ({
                                                            color:
                                                                theme.palette.mode === 'light' ? 'grey.400' : 'grey.600',
                                                            ...(selectedItemIndex === index && {
                                                                color: 'primary.main',
                                                            }),
                                                        })}
                                                    >
                                                        {icon}
                                                    </Box>
                                                    <div>
                                                        <Typography color="text.primary" fontWeight="medium" gutterBottom>
                                                            {title}
                                                        </Typography>
                                                        <Typography color="text.secondary" variant="body2" sx={{ mb: 1.5 }}>
                                                            {description}
                                                        </Typography>
                                                        <Link
                                                            color="primary"
                                                            variant="body2"
                                                            fontWeight="bold"
                                                            sx={{
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                '& > svg': { transition: '0.2s' },
                                                                '&:hover > svg': { transform: 'translateX(2px)' },
                                                            }}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                            }}
                                                        >
                                                            <span>Learn more</span>
                                                            <ChevronRightRoundedIcon fontSize="small" sx={{ mt: '1px', ml: '2px' }} />
                                                        </Link>
                                                    </div>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} sm={6}>
                                        <Button variant="contained" onClick={() => handleSubmit()} color="primary">
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Login;