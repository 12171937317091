import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './App.css';
import './assets/styles/customStyle.css';
import DomainDetailsPage from './componets/DomainDetailsPage';
import PageNotFound from './componets/PageNotFound';
import ScrollTop from './componets/ScrollTop';
import getLPTheme from './getLPTheme';
import { AuthorizeUser, LoginAuth } from './middleware/auth';
import Contact from './pages/Contact';
import Dashboad from './pages/Dashboard';
import HeaderBar from './pages/Header';
import HomePage from './pages/Home';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Recovery from './pages/Recovery';
import Register from './pages/Register';
import SearchDomain from './pages/SearchDomain';
import SellDomain from './pages/SellDomain';
import SupportTawk from './pages/Support';
import TermsOfServices from './pages/TermsOfServices';
import VerifyEmail from './pages/VerifyEmail';
import AccessDashboard from './componets/Dashboard/AccessDashboard';
export const UserContext = React.createContext(null);

function App() {
  const [user, setUser] = useState(null);
  const LPtheme = createTheme(getLPTheme('light'));

  return (
    <ThemeProvider theme={LPtheme}>
      <UserContext.Provider value={{ user: user, setUser: setUser }}>
        <BrowserRouter>
          <ScrollTop />   
          <Toaster />
          <Routes>
            <Route path='/'>
              <Route path="" element={<><HeaderBar /> <HomePage /></>} />
              <Route path="selldomain" element={<><HeaderBar /> <SellDomain /></>} />
              <Route path="verify_email" element={<><HeaderBar /> <VerifyEmail /></>} />
              <Route path="register" element={<><LoginAuth><HeaderBar /> <Register /></LoginAuth></>} />
              <Route path="login" element={<><HeaderBar /> <Login /></>} />
              <Route path="recovery" element={<><HeaderBar /> <Recovery /></>} />
              <Route path="dashboard/*" element={<><AuthorizeUser><Dashboad /></AuthorizeUser></>} />
              <Route path="search" element={<><HeaderBar /><SearchDomain /></>} />
              <Route path="contact" element={<><HeaderBar /><Contact /></>} />
              <Route path="domain/:name" element={<><HeaderBar /><DomainDetailsPage /></>} />
              <Route path="terms-of-servies" element={<><HeaderBar /><TermsOfServices /></>} />
              <Route path="privacy-policy" element={<><HeaderBar /><PrivacyPolicy /></>} />
              <Route path="accessDashboard/:token" element={<><HeaderBar /><AccessDashboard /></>} />
              <Route path="*" element={<><HeaderBar /> <PageNotFound /></>} />
            </Route>
          </Routes>
          <SupportTawk />
        </BrowserRouter>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
