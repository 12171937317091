import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Box, Card, Container, Grid, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LoadingButton from '@mui/lab/LoadingButton';
import toast, { Toaster } from 'react-hot-toast';
import { getWishlistDomains, updateWishlist, createPaymentIntent } from '../../helper/helper';
import { Spinner } from '../Loader';
import MetaTags from '../MetaTags';
import CheckoutForm from '../CheckoutForm';

export default function Wishlist() {
    const { user, setUser } = useContext(UserContext);
    const [loading, setloading] = useState(true);
    const [domainData, setdomainData] = useState([]);
    const [randomStyles, setRandomStyles] = useState([]);
    const [wishlist, setwishlist] = useState([]);
    const [wishlistShow, setwishlistShow] = useState(true);
    const navigate = useNavigate();
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [buynowLoading, setbuynowLoading] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState(null);

    const [styleData, setStyleData] = useState([
        { backgroundColor: '#F2EED9' },
        { backgroundColor: '#DFF2D9' },
        { backgroundColor: '#D9EEF2' },
        { backgroundColor: '#D9E1F2' },
        { backgroundColor: '#D9E1F2' },
        { backgroundColor: '#EAD9F2' },
    ]);

    useEffect(() => {
        async function fetchFirstData() {
            if (user && user !== "" && user !== undefined) {
                let whishlistPromise = getWishlistDomains({ wishlist: user.wishlist });
                whishlistPromise.then((res) => {
                    if (res && res.data) {
                        setdomainData(res.data);
                    } else {
                        setdomainData([]);
                    }
                    setloading(false);
                }).catch(error => {
                    setloading(false);
                });
            }
            if (user && user !== "" && user !== undefined) {
                setwishlist(user.wishlist);
                if (user.user_type === "2") {
                    setwishlistShow(true);
                } else {
                    setwishlistShow(false);
                }
            } else {
                setwishlist([]);
                setwishlistShow(true);
            }
            setStripePromise(loadStripe(process.env.REACT_APP_LOAD_STRIPE));
        }
        fetchFirstData();
    }, [user]);

    useEffect(() => {
        if (styleData && styleData.length > 0) {
            const newRandomStyles = styleData.sort(() => 0.5 - Math.random());
            setRandomStyles(newRandomStyles);
        }
    }, [styleData]);

    const handleClickWishlist = (id) => {
        if (user && user !== "") {
            let newWishlist = [...wishlist];
            if (wishlist.includes(id)) {
                newWishlist = wishlist.filter((tldId) => tldId !== id);
            } else {
                newWishlist.push(id);
            }
            let wishlistPromise = updateWishlist({ wishlist: newWishlist });
            wishlistPromise.then((res) => {
                setwishlist(newWishlist);
                setUser(preData => ({ ...preData, wishlist: newWishlist }))
            }).catch(error => {
                // console.log('Login error:', error);
            });
        } else {
            navigate('/login');
        }
    }

    const handleBuyNow = (domain) => {
        if (user && user !== "" && user !== null) {
            if (user?.shipping && user.shipping !== "" && user.shipping !== null) {
                setbuynowLoading(true);
                setSelectedDomain(domain);
                const paymentPromise = createPaymentIntent({ 
                    amount: domain?.price || 9999, 
                    shipping: user.shipping, 
                    name: user.first_name, 
                    metadata: { 
                        domain_id: domain._id, 
                        buyer_id: user._id, 
                        seller_id: domain.user_id, 
                        domain_name: domain.name 
                    } 
                });
                paymentPromise.then((res) => {
                    const { data } = res;
                    if (data && data !== "") {
                        setClientSecret(data.clientSecret)
                    }
                    setbuynowLoading(false);
                }).catch(error => {
                    setbuynowLoading(false);
                });
            } else {
                const myPromise = new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 3000);
                });

                toast.promise(myPromise,
                    {
                        loading: 'First you have to add Address',
                        success: 'Redirecting...',
                        error: 'Something went wrong!',
                    }, { duration: 1500, }
                );

                myPromise.then(() => {
                    setTimeout(() => {
                        navigate('/dashboard/account');
                    }, 1500);
                });
            }
        } else {
            navigate('/login');
        }
    }

    const isItemInWishlist = (itemId, wishlistArray) => {
        return wishlistArray.includes(itemId);
    };

    return (
        <>
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <MetaTags title="Wishlist" description="View your wishlist items." />
            {loading ?
                <div className="flex items-center justify-center h-screen w-full">
                    <Stack direction="row" alignItems="center" gap={2}>
                        <Spinner />
                    </Stack>
                </div>
                :
                <>
                    <Container
                        sx={{
                            pt: { xs: 4, sm: 5 },
                            pb: { xs: 4, sm: 5 },
                            position: 'relative',
                            width: "100%",
                            maxWidth: "1400px !important",
                            margin: "auto",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: { xs: 3, sm: 6 },
                        }}
                    >
                        <div className="w-full">
                            {domainData && domainData.length > 0 ?
                                <Box sx={{
                                    padding: { xs: '20px 15px', sm: "40px 15px" },
                                    textAlign: 'center',
                                }}>
                                    <Grid container spacing={4}>
                                        {domainData.map((itemD, index) => (
                                            <Grid key={index} item>
                                                <Card
                                                    sx={{
                                                        height: 250,
                                                        width: 300,
                                                        backgroundColor: randomStyles[index % randomStyles.length].backgroundColor,
                                                        color: "#000",
                                                        padding: '0 10px',
                                                        textTransform: "capitalize",
                                                        fontSize: (itemD.name).length > 15 ? '1.1rem' : '1.3rem',
                                                        wordBreak: 'break-word',
                                                        transition: 'transform 0.3s ease',
                                                    }}
                                                >
                                                    <div className='flex justify-center items-center h-full relative'>
                                                        {wishlistShow &&
                                                            <div className='absolute top-2 right-2 cursor-pointer' onClick={() => { handleClickWishlist(itemD._id); }}>
                                                                {isItemInWishlist(itemD._id, wishlist) ? (
                                                                    <FavoriteIcon sx={{ color: "#000" }} />
                                                                ) : (
                                                                    <FavoriteBorderIcon sx={{ color: "#000" }} />
                                                                )}
                                                            </div>
                                                        }
                                                        <div className='flex flex-col items-center justify-center'>
                                                            <Link to={`/domain/${itemD.name}`}>
                                                                {itemD.name}
                                                            </Link>
                                                            <span className='mt-4'>
                                                                ${itemD.price}
                                                            </span>
                                                            {clientSecret && stripePromise && selectedDomain?._id === itemD._id ? (
                                                                <Elements stripe={stripePromise} options={{ clientSecret }}>
                                                                    <CheckoutForm />
                                                                </Elements>
                                                            ) : (
                                                                <LoadingButton 
                                                                    loading={buynowLoading && selectedDomain?._id === itemD._id} 
                                                                    variant="contained" 
                                                                    sx={{
                                                                        color: "#fff",
                                                                        backgroundColor: "#425CD9",
                                                                        padding: "10px",
                                                                        borderRadius: "5px",
                                                                        boxShadow: "unset",
                                                                        marginTop: "10px"
                                                                    }} 
                                                                    onClick={() => handleBuyNow(itemD)}
                                                                >
                                                                    Proceed to pay
                                                                </LoadingButton>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                :
                                <Typography variant='h5' sx={{ mt: 5, textAlign: 'center', color: "#000" }}>
                                    Your Wishlist is empty!
                                </Typography>
                            }
                        </div>
                    </Container>
                </>
            }
        </>
    );
}