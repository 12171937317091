import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MoreIcon from "@mui/icons-material/MoreVert";
import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { items } from "../componets/Dashboard/Items";
import Main from "../componets/Dashboard/Main";
import Sidebar from "../componets/Dashboard/Sidebar";
import { fetchUserOrParentData, logout } from "../helper/helper";
import useFetch from "../hooks/fetch.hook";

function LogoutButton() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate("/");
    window.location.reload();
  };

  return (
    <MenuItem onClick={handleLogout}>
      <LogoutIcon sx={{ color: "#000000" }} />
      <Typography sx={{ marginLeft: 1 }} textAlign="center">
        Logout
      </Typography>
    </MenuItem>
  );
}

const settings = [
  {
    name: "Profile",
    link: "/dashboard/account",
    icon: <AccountCircle sx={{ color: "#000000" }} />,
  },
  { name: "Logout", element: <LogoutButton /> },
];

export default function DashboardLayout() {
  const [{ apiData }] = useFetch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [mobileDrawerOpen, setmobileDrawerOpen] = useState(false);
  const [userType, setuserType] = useState(null);
  const [userData, setuserData] = useState(null);
  const [dashboardTitle, setdashboardTitle] = useState('');
  const navigations = useNavigate();
  const location = useLocation();
  // const [{ apiData }] = useFetch();
  const [parentUserData, setParentUserData] = useState(null);

  const itemNotInList = [{ path: '/dashboard/domain/', title: "Domain" }, { path: '/dashboard/user/', title: "User" }]

  useEffect(() => {
    const fetchData = async () => {
      if (items && items.length > 0 && userType) {
        const currentTitle = items.find(item => item.user === userType && item.path === location.pathname)
        if (currentTitle) {
          setdashboardTitle(currentTitle.title);
        } else {
          const currentNotTitle = itemNotInList.filter(item => { return location.pathname.includes(item.path); })
          if (currentNotTitle && currentNotTitle.length > 0) {
            setdashboardTitle(currentNotTitle[0].title);
          } else {
            setdashboardTitle('');
          }
        }
      }
    }
    fetchData();

  }, [location, userType]);



  useEffect(() => {
      const fetchUserData = async () => {
          try {
              const userData = await fetchUserOrParentData(apiData);
              if (userData) {
                  setuserType(userData.user_type);
                  setuserData(userData);
  
                  // If the user is a team member, also store the parent user's data
                  if (apiData.user_type === '1' && apiData.parent_user) {
                      setParentUserData(userData);
                      console.log(userData);  
                      
                  }
              }
          } catch (error) {
              console.error("Error fetching user data:", error);
          }
      };
  
      fetchUserData();
  }, [apiData]);
  

  const toggleDrawer = () => () => {
    setdrawerOpen(!drawerOpen);
    setmobileDrawerOpen(!mobileDrawerOpen);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigateUserMenu = (link) => {
    navigations(link);
    setAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {settings.map((item, index) => (
        <div key={index}>
          {item.link ? (
            <>
              <MenuItem
                onClick={(e) => handleNavigateUserMenu(item.link)}
              >
                {item.icon}
                <Typography sx={{ marginLeft: 1 }} textAlign="center">
                  {item.name}
                </Typography>
              </MenuItem>
            </>
          ) : (
            item.element
          )}
        </div>
      ))}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {settings.map((item, index) => (
        <div key={index}>
          {item.link ? (
            <>
              <MenuItem
                onClick={(e) => handleNavigateUserMenu(item.link)}
              >
                {item.icon}
                <Typography sx={{ marginLeft: 1 }} textAlign="center">
                  {item.name}
                </Typography>
              </MenuItem>
            </>
          ) : (
            item.element
          )}
        </div>
      ))}
    </Menu>
  );

  return (
    <div className="w-full">
      <div className="text-gray-200">
        <Sidebar items={items} userType={userType} open={drawerOpen} mobileOpen={mobileDrawerOpen} handleClick={toggleDrawer()} />
        <div className={`w-full ${drawerOpen ? "md:pl-[260px]" : "md:pl-0"} transition-all`}>
          <div>
            <AppBar
              position="static"
              sx={{
                color: "#000",
                backgroundColor: "#ffffff",
                boxShadow: "none",
                borderBottom: "1px solid #e5e5e5",
              }}
            >
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer()}
                  sx={{ mr: 1, display: { xs: 'none', sm: "flex" } }}
                >
                  {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer mobile"
                  onClick={toggleDrawer()}
                  sx={{ mr: 1, display: { xs: 'flex', sm: "none" } }}
                >
                  {mobileDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
                <Typography variant="h6" noWrap component="div">{dashboardTitle}</Typography>
                {/* <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }} > XiDomain </Typography> */}
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    {userData && userData.first_name && <div className="text-sm mr-2">{userData.first_name}</div>} <AccountCircle />
                  </IconButton>
                </Box>
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
          </div>
          <Main userType={userType} userData={userData} parentUserData={parentUserData}  />
        </div>
      </div>
    </div>
  );
}
