import React, { useState, useEffect } from 'react';
import { Button, Card, TextField, Box, Typography, CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
import { sendInvite } from '../../helper/helper';
import { jwtDecode } from 'jwt-decode';
import TeamMembersTable from './TeamMembersTable';
import { useNavigate } from 'react-router-dom';


const AddMember = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            toast.error('Please login to invite members');
            navigate('/login');
            return;
        }

        try {
            const decoded = jwtDecode(token);
            if (decoded.role === 'team_member') {
                toast.error('You do not have permission to access this page');
                navigate('/dashboard');
                return;
            }

            if (!decoded.userID && !decoded.userId) {
                toast.error('Invalid session. Please login again.');
                navigate('/login');
            }
        } catch (error) {
            console.error("Invalid Token:", error);
            toast.error('Session expired. Please login again.');
            navigate('/login');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.error('Email is required');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error('Please enter a valid email address');
            return;
        }

        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No authentication token found');

            const response = await sendInvite({ email });
            if (response?.status === 409) {  
                toast.error('User already exists');  
            } else {
                toast.success('Invitation sent successfully');
                setEmail('');
            }
            setEmail('');
        } catch (error) {
            if (error.message === 'User already exists') {
                toast.error('User already exists');
            } else {
                toast.error(error.message || 'Failed to send invitation');
            }
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card sx={{ mb: 4 }}>
                <TeamMembersTable />
            </Card>
            <Card sx={{ maxWidth: 600, mx: 'auto', mt: 4, p: 4 }} >
                <Typography variant="h5" gutterBottom>
                    Add Team Member
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        required
                        disabled={loading}
                        type="email"
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        disabled={loading}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Send Invitation'}
                    </Button>
                </Box>
            </Card>
        </>
    );
};

export default AddMember;