import axios from 'axios';
import { jwtDecode } from "jwt-decode";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN || "http://localhost:8080";

export async function register(credentials) {
    try {

        const { data: { message } } = await axios.post('/api/register', credentials);
        return Promise.resolve(message);
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function getPageSetting(page) {
    try {
        const { data } = await axios.get(`/api/getPageSetting?page=${page}`);
        return { data };
    } catch (error) {
        return { error: "Page Not Found" };
    }
}

export async function updatedSetting(page, data) {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.post('/api/updatedSetting', { page, data }, { headers: { "authorization": `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        console.error('Error updating page settings:', error);
        return { error: "Failed to update settings" };
    }
}


export const verifyEmailToken = async (token) => {
    try {
        const { data: { message } } = await axios.post('/api/verifyConfirmEmailToken', { token })
        return Promise.resolve(message);
    }
    catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function login({ email, password }) {
    try {
        if (email) {
            const { data } = await axios.post('/api/login', { email, password });
            // console.log(data);
            return Promise.resolve({ data });
        }
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function loginUserWithGoogle(tokenResponse) {
    try {
        const { data } = await axios.post('/api/loginWithGoogle', {
            credential: tokenResponse.credential,
            user_type: "1"
        });
        return Promise.resolve({ data });
    } catch (error) {
        throw new Error(error.response?.data?.error || "Google Login Failed");
    }
}

export async function loginWithGoogleUpdate(response) {
    try {
        const { data } = await axios.post('/api/loginWithGoogleUpdate', response);
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject({ error });
    }
}

export async function getEmailFromToken() {
    const token = localStorage.getItem('token');
    // console.log('token', token)
    if (!token) return Promise.reject("Cannot find token")

    let decode = jwtDecode(token)
    // console.log(decode);
    return decode;
}

export async function logout() {
    localStorage.removeItem('token');
}

export async function getUser(email) {
    try {
        const { data } = await axios.get(`/api/user/${email}`);
        return { data };
    } catch (error) {
        // return { error: "Password Does Not Match!" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getUserData() {
    try {
        const token = localStorage.getItem('token')
        // eslint-disable-next-line
        const { data } = await axios.get('/api/getUserList', { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "User Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function deleteUser(id) {
    try {
        const token = localStorage.getItem('token');
        // console.log(token);
        const data = await axios.delete('/api/adminDeleteUser', { params: { id }, headers: { "authorization": `Bearer ${token}` } });
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}


export async function updateUser(userData) {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No authentication token found');
  
      const { data } = await axios.put('/api/updateUser',   
        userData,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );
      console.log("muje update data mila", data);
      
      return data;
    } catch (error) {
      throw new Error(error.response?.data?.error || "Failed to update user");
    }
  }
  

export async function updateWishlist(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.put('/api/updateWishlist', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function passwordChange(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.put('/api/passwordChange', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function adminPasswordChange(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.put('/api/adminPasswordChange', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function createDomain(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/createDomain', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}


export async function getDomains(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post('/api/getDomains', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getAdminDomains(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post('/api/getAdminDomains', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getBuyDomains(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post('/api/getBuyDomains', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getDomain(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/getDomain/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getUserDomain(name) {
    try {
        const { data } = await axios.get(`/api/getUserDomain/${name}`);
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getSellerDomainCounts(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/getSellerDomainCounts/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getBuyerDomainCounts(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/getBuyerDomainCounts/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getWishlistDomains(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post('/api/getWishlistDomains', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function deleteDomain(user_id, id) {
    try {
        const token = localStorage.getItem('token');
        // console.log(token);
        const data = await axios.delete('/api/deleteDomain', { params: { user_id, id }, headers: { "authorization": `Bearer ${token}` } });
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function adminDeleteDomain(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.delete(`/api/adminDeleteDomain/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getAllDomains() {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get('/api/getAllDomains', { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: error || "Category not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getAllTransactions() {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get('/api/getAllTransactions', { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: error || "Category not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getAllCategory() {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get('/api/getAllCategory', { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: error || "Category not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function updateDomainCategory(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.put('/api/updateDomainCategory', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: error || "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function adminUpdateDomain(id, updatedata) {
    try {
        const token = localStorage.getItem('token');
        const { data } = await axios.put(`/api/adminUpdateDomain/${id}`, updatedata, {
            headers: { "authorization": `Bearer ${token}` }
        });
        return data; // Directly return the data
    } catch (error) {
        return Promise.reject(error.response?.data?.error || "An error occurred");
    }
}

export async function verifyDomain(response) {
    try {
        const token = localStorage.getItem('token')
        const { data: { message } } = await axios.post('/api/verifyDomain', response, { headers: { "authorization": `Bearer ${token}` } });
        // console.log('lcllc', message)
        return Promise.resolve(message);;
    } catch (error) {
        // console.log('error', error)
        // return { error: error }
        return Promise.reject(error.response.data.error);
    }
}

export async function searchDomain(response) {
    try {
        const queryString = new URLSearchParams(response).toString();
        const { data } = await axios.get(`/api/searchDomain/?${queryString}`);
        return { data };
    } catch (error) {
        // return { error: "Password Does Not Match!" }
        // return Promise.reject(error.response.data.error);
    }
}

export async function createCategory(response) {
    try {
        const token = localStorage.getItem('token')
        const { data: { message } } = await axios.post('/api/createCategory', response, { headers: { "authorization": `Bearer ${token}` } });
        return Promise.resolve(message);;
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function updateCategory(response) {
    try {
        const token = localStorage.getItem('token')
        const { data: { message } } = await axios.put('/api/updateCategory', response, { headers: { "authorization": `Bearer ${token}` } });
        return Promise.resolve(message);;
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function deleteCategory(response) {
    try {
        const token = localStorage.getItem('token')
        const data = await axios.put('/api/deleteCategory', response, { headers: { "authorization": `Bearer ${token}` } });
        return data;
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}


export async function createPaymentIntent(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post('/api/createPaymentIntent', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getCountryList() {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get('/api/getCountryList', { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "User Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getStateList(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/getStateList/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "User Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getCityList(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/getCityList/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "User Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getBuytransactions(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/getBuytransactions', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function getAdminBuytransactions(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/getAdminBuytransactions', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function generateOTP(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/generateOTP', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}


export async function verifyOTP(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/verifyOTP', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function resetPassword(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.put('/api/resetPassword', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}


export async function findDetails() {
    try {
        const { data } = await axios.get(`/api/findDetails`);
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getWishlistList(response) {
    try {
        const { data } = await axios.post('/api/getWishlistList', response);
        return Promise.resolve({ data })
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function searchSellerDomain(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/searchSellerDomain', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function createContact(response) {
    try {

        const data = await axios.post('/api/createContact', response)
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function descriptionsSet(name) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/descriptionsSet/${name}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "User Data not found" }
        return Promise.reject(error.response.data.error);
    }
}


export async function sendInvite({ email }) {
    try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const { data } = await axios.post('/api/send-invite',
            { email },
            {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            }
        );

        return data;
    } catch (error) {
        if (error.response?.status === 400) {  
            throw new Error('User already exists');  
        }
        throw new Error(error.response?.data?.error || "Failed to send invitation");
    }
}


export async function validateInviteToken(token) {
    try {
        const { data } = await axios.get(`/api/accessDashboard/${token}`);
        if (!data.valid) throw new Error('Invalid invitation');
        return data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Invalid invitation');
    }
}

export async function acceptInvite({ token, password, inviterId }) {
    try {
        const { data } = await axios.post('/api/accept-invite', {
            token,
            password,
            inviterId
        });
        return data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Failed to set password');
    }
}

export async function fetchUserOrParentData(apiData) {
    if (!apiData || apiData === "") return null;

    const token = localStorage.getItem('token');
    if (!token) throw new Error('No authentication token found');

    if (apiData.user_type === '1' && apiData.parent_user) {
        try {
            const { data } = await axios.get(`/api/users/${apiData.parent_user}`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            console.error('Error fetching parent user data:', error);
            throw error;
        }
    }
    return apiData;
}


export async function completeInviteRegistration({ token, password }) {
    try {
        const { data } = await axios.post('/api/complete-invite-registration',
            { token, password }
        );
        return data;
    } catch (error) {
        throw new Error(error.response?.data?.error || "Registration failed");
    }
}

export async function getTeamMembers() {
    try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');
        const response = await axios.get('/api/team-members', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to fetch team members");
    }
}
export async function registerUser({ token, password }) {
    const response = await axios.post('/api/complete-invite-registration', {
        token,
        password
    });
    return response.data;
}

export async function loginUser({ token, password }) {
    const response = await axios.post('/api/login', {
        token,
        password
    });
    return response.data;
}

