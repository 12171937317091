import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from '@mui/material/styles';
import { jwtDecode } from 'jwt-decode';
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';


function importAll(r) {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAll(require.context('../../assets/Images', false, /\.(png|jpe?g|svg)$/));
const logo = `${images["XIDOMAINSD.png"]}`;

function Sidebar(props) {
    const { items, handleClick, open, userType, mobileOpen } = props;
    // const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const DrawerHeader = styled('div')(({ theme }) => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));

    const getUserRole = () => {
        const token = localStorage.getItem('token');
        if (!token) return null;
        try {
            const decoded = jwtDecode(token);
            return decoded.role;
        } catch (error) {
            return null;
        }
    };

    const currentRole = getUserRole();

    const DrawerList = (
        <Box role="presentation">
            <List>
                {items &&
                    items.map((item, index) => {
                        if (item.hideForRoles && item.hideForRoles.includes(currentRole)) {
                            return null;
                        }

                        return userType === item.user && (
                            <ListItem key={index} disablePadding>
                                <ListItemButton selected={location.pathname === item.path} component={Link} to={item.path}
                                    sx={{
                                        pl: 2,
                                        backgroundColor: location.pathname === item.path ? "#e6f4ff" : "transparent",
                                        color: location.pathname === item.path ? "#1677ff" : "#000",
                                        borderRight: location.pathname === item.path ? "2px solid" : "unset"
                                    }}>
                                    <ListItemIcon sx={{ color: "currentcolor", fontSize: "24px", justifyContent: "center" }}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.title} />
                                </ListItemButton>
                            </ListItem>

                        )
                    })}
            </List>
        </Box>
    );

    const desktop_sidebar = (
        <Drawer
            open={open}
            onClose={handleClick}
            ModalProps={{ keepMounted: true }}
            PaperProps={{
                sx: {
                    display: { xs: "none", md: "block" },
                    backgroundColor: "white",
                    color: "black",
                    padding: "15px 0",
                    width: 260,
                    boxSizing: "border-box",
                },
            }}
            variant="persistent"
        >
            <Link to="/"><img src={logo} className="w-52 m-auto px-2 py-4" alt='xidomains' /></Link>
            {/* <Typography variant="h3" align="center"><Link to="/">XiDomain</Link> </Typography> */}
            <br />
            {/* <Typography variant="h6" sx={{ color: "#8c8c8c", fontSize: "13px", pl: 2, mb: 1 }} component="div">
                Navigation
            </Typography> */}
            {DrawerList}
        </Drawer>
    );

    const mobile_sidebar = (
        <Drawer
            anchor="left"
            open={mobileOpen}
            onClose={handleClick}
            onClick={handleClick}
            ModalProps={{
                keepMounted: true,
            }}
            PaperProps={{
                sx: {
                    display: { xs: "block", md: "none" },
                    backgroundColor: "white",
                    color: "black",
                    padding: "15px 0",
                    width: 310,
                    boxSizing: "border-box",
                },
            }}
            variant="persistent"
        >
            <DrawerHeader sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                {/* <Typography variant="h3" align="center"><Link to="/">XiDomain</Link> </Typography> */}
                <Link to="/"><img src={logo} className="w-52 m-auto p-2" alt='xidomains' /></Link>
                <IconButton onClick={() => { handleClick(); }}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            {DrawerList}
        </Drawer >
    );

    return (
        <div className="w-full">
            <div className="md:block hidden">{desktop_sidebar}</div>
            <div className="md:hidden block">{mobile_sidebar}</div>
        </div>
    );
}

export default Sidebar;
