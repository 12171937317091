import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { validateInviteToken, acceptInvite } from '../../helper/helper';
import { Button, Card, TextField, Box, Typography, CircularProgress } from '@mui/material';

const AccessDashboard = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [invitationDetails, setInvitationDetails] = useState(null);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await validateInviteToken(token);
        if (response.valid) {
          setInvitationDetails({
            email: response.invitation.email,
            inviterId: response.invitation.inviterId
          });
        } else {
          toast.error('Invalid or expired invitation');
          navigate('/login');
        }
      } catch (error) {
        toast.error(error.message || 'Invalid invitation');
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    validateToken();
  }, [token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      toast.error('All fields are required');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      setLoading(true);
      const response = await acceptInvite({ 
        token, 
        password,
        inviterId: invitationDetails.inviterId 
      });
      
      if (response.token) {
        localStorage.setItem('token', response.token);
        toast.success('Dashboard access granted!');
        console.log('Dashboard access granted!');
        navigate('/dashboard');
      }
    } catch (error) {
      toast.error(error.message || 'Failed to access dashboard');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card sx={{ maxWidth: 600, mx: 'auto', mt: 4, p: 4 }}>
      <Typography variant="h5" gutterBottom align="center">
        Set Password
      </Typography>
      {invitationDetails && (
        <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
          You've been invited to access the dashboard as {invitationDetails.email}
        </Typography>
      )}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          margin="normal"
          required
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Access Dashboard'}
        </Button>
      </Box>
    </Card>
  );
};

export default AccessDashboard;