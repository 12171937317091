import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton, Stack, Tooltip, Typography, CircularProgress, Button } from '@mui/material';
import { SendOutlined, RefreshOutlined } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { getTeamMembers, sendInvite } from '../../helper/helper';


const TeamMembersTable = () => {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resendLoading, setResendLoading] = useState(null);
   

    useEffect(() => {
        fetchTeamMembers();
    }, []);

    const fetchTeamMembers = async () => {
        try {
            setLoading(true);
            const response = await getTeamMembers();
            if (response?.data && Array.isArray(response.data)) {
                setMembers(response.data);
            } else {
                setMembers([]);
            }
        } catch (error) {
            console.error("Error fetching team members:", error);
            toast.error("Failed to load team members");
        } finally {
            setLoading(false);
        }
    };

    const handleResendInvite = async (email, id) => {
        try {
            setResendLoading(id);
            await sendInvite({ email });
            toast.success(`Invitation resent to ${email}`);
        } catch (error) {
            console.error('Resend invitation error:', error);
            toast.error(error.message || 'Failed to resend invitation');
        } finally {
            setResendLoading(null);
        }
    };

    const columns = [
        {
            field: 'email',
            headerName: 'Email',
            flex: 2,
            minWidth: 200,
            headerClassName: "text-[12px] bg-[#ffffff] text-black-100"
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 150,
            headerClassName: "text-[12px] bg-[#ffffff] text-black-100",
            renderCell: (params) => (
                <div className="p-2 h-full">
                    <div className={`relative flex items-center justify-start h-full py-3 px-2 rounded-lg ${params.row.status === 'Active'
                            ? 'text-[#17BB09] bg-[#17BB09]/[11%]'
                            : 'text-[#BB0909] bg-[#BB0909]/[11%]'
                        }`}>
                        <span className={`w-2 h-2 rounded-full min-w-2 ${params.row.status === 'Active'
                                ? 'bg-[#17BB09]'
                                : 'bg-[#BB0909]'
                            }`}></span>
                        <span className='ml-2'>{params.row.status}</span>
                    </div>
                </div>
            )
        },
        {
            field: 'invitedDate',
            headerName: 'Invited On',
            flex: 1,
            minWidth: 150,
            headerClassName: "text-[12px] bg-[#ffffff] text-black-100",
        },
        {
            field: 'lastLoginDate',
            headerName: 'Last Login',
            flex: 1,
            minWidth: 150,
            headerClassName: "text-[12px] bg-[#ffffff] text-black-100",
            renderCell: (params) => (
                <span>{params.row.lastLoginDate || 'Never'}</span>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 100,
            flex: 1,
            headerClassName: "text-[12px] bg-[#ffffff] text-black-100",
            renderCell: (params) => (
                <div>
                    {params.row.status !== 'Active' && (
                        <Tooltip title="Resend Invitation">
                            <IconButton
                                onClick={() => handleResendInvite(params.row.email, params.row.id)}
                                disabled={resendLoading === params.row.id}
                            >
                                {resendLoading === params.row.id ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <SendOutlined fontSize="small" />
                                )}
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            )
        }
    ];

    return (
        <div className="w-full">
            <div className="flex justify-between items-center p-3 text-black border">
                <Typography variant="h6">Team Members</Typography>
                {/* <Button
                    startIcon={<RefreshOutlined />}
                    onClick={fetchTeamMembers}
                    variant="outlined"
                    disabled={loading}
                >
                    Refresh
                </Button> */}
            </div>

            <DataGrid
                autoHeight
                loading={loading}
                rows={members}
                columns={columns}
                disableRowSelectionOnClick
                slots={{
                    loadingOverlay: () => (
                        <div className='flex w-full justify-center h-screen items-center'>
                            <CircularProgress />
                        </div>
                    ),
                    noRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            <Typography p={5} textAlign="center">
                                No team members found. Invite members to get started.
                            </Typography>
                        </Stack>
                    )
                }}
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    },
                    color: "#000",
                    backgroundColor: "#ffffff", 
                    fontSize: "13px",
                    lineHeight: "18px",
                    cursor: "pointer",
                    fontWeight: '600',
                    '.MuiDataGrid-footerContainer': {
                        justifyContent: 'center',
                    },
                    '.MuiTablePagination-root': {
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }
                }}

            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
            }}
            pageSizeOptions={[10]}
            hideFooterSelectedRowCount={true} 
            />
        </div>
    );
};

export default TeamMembersTable;